<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card mb-12">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <h6>Data {{ currentRouteName }}</h6>
              </div>
              <div class="col">
                <router-link
                  to="/products/add"
                  class="btn btn-primary float-right margin-0 pd-10"
                >
                  <span><i class="fas fa-plus-circle"></i></span>
                  Add
                </router-link>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body p-3">
              <TableFilter
                :f_submit="searchSubmit"
                :f_loading="loading"
                :f_name="true"
                :f_paymentid="true"
                :f_category="true"
              ></TableFilter>
            </div>
            <div class="card-body p-3">
              <EasyDataTable
                show-index
                buttons-pagination
                alternating
                v-model:server-options="serverOptions"
                :server-items-length="numberOfPages"
                :loading="loading"
                :headers="headers"
                :items="transactions"
                :search-field="searchField"
                :search-value="searchValue"
              >
                <template #item-is_active="{ is_active }">
                  <div class="bg-success text-center" v-if="is_active == 1">
                    <span class="badge badge-success">Active</span>
                  </div>
                  <div class="bg-gray-600 text-center" v-else>
                    <span class="badge badge-warning">Not Active</span>
                  </div>
                </template>
                <template #item-view="item">
                  <router-link
                    :to="`/products/${item.payment_id}/${item.pg_id}/view`"
                    class="btn btn-success my-3"
                  >
                    Edit
                  </router-link>
                </template>
                <template #loading>
                  <img :src="imageLoading" style="width: 100px; height: 80px" />
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TableFilter from "@/views/TableFilter";
export default {
  name: "TableProducts",
  components: {
    TableFilter,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = dataParam;
      this.searchParam.trxType = "payin";
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    viewProduct(item) {
      this.editProduct = true;
      this.productItemData = item;
    },
    fetchData() {
      this.loading = true;
      this.searchParam.trxType = "payin";
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getProductsListData(this.searchParam).then(
        (response) => {
          this.transactions = response.data.data.data;
          this.numberOfPages = response.data.data.dataCount;
          this.loading = false;
        }
      );
    },
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    transactions: [],
    content: "",
    numberOfPages: 0,
    searchField: "refNo",
    searchValue: "",
    loading: false,
    productItemData: [],
    editProduct: false,
    headers: [
      { text: "Product Id", value: "payment_id", sortable: true },
      { text: "Product Name", value: "product_name", sortable: true },
      { text: "Category", value: "category", sortable: true },
      { text: "PG ID", value: "pg_id", sortable: true },
      { text: "PG Name", value: "pg_name", sortable: true },
      { text: "Settlement Days", value: "settlement_days", sortable: true },
      { text: "Active", value: "is_active", sortable: true },
      { text: "View", value: "view", sortable: false },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
  }),
  mounted() {
    this.fetchData();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>

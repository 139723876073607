export default {
  GET_PARTNER_LINK: 'dashboard/getPartnerLink',
  GET_STATUS_CODE_LIST: 'dashboard/getStatusCodeList',
  GET_LINKED_PARTNER: 'dashboard/getLinkedPartner',
  GET_LINKED_CHANNEL: 'dashboard/getLinkedChannelList',
  GET_TRX: 'dashboard/getTransactions',
  GET_PAYIN_CHART_DATA: 'dashboard/generateChartDataPayIn',
  GET_PAYOUT_CHART_DATA: 'dashboard/generateChartDataPayOut',
  GET_LINK_DOWNLOAD: 'dashboard/getLinkDownload',
  GET_BALANCE_INFO: 'dashboard/getUserBalanceData',
  GET_BALANCE_HISTORY: 'dashboard/getBalanceHistory',
  GET_TOPUP_DATA: 'dashboard/getTopupRequestData',
  GET_PAYMENT_GATEWAYS: 'dashboard/getPaymentGatewayData',
  GET_PRODUCT_CATEGORIES: 'dashboard/getProductCategory',
  GET_PARTNER_LIST: 'dashboard/getPartnersList',
  GET_PAYMENT_MODELS: 'dashboard/getPaymentModel',
  GET_PRODUCT_PRICES: 'dashboard/getProductsList',
  GET_CHANNEL_LIST: 'dashboard/getChannelList',
  GET_PARTNER_INFO: 'dashboard/getPartnerInfo',
  GET_CHART_PAY: 'dashboard/generateChartDataPay',
  GET_FEE: 'dashboard/getFee',
  GET_PAYMENTLIST: 'dashboard/getPaymentList',
  GET_VA_POOLING_LIST: 'dashboard/getVaPoolingList',
  GET_PARTNER: 'dashboard/getPartners',
  GET_PAYIN_BALANCE: 'dashboard/getPayInBalance',
  GET_CALLBACK: 'callback/',
  GET_CHECK_TRANSACTION: 'check-transaction/',
  GENERATE_SIGNATURE_PAYIN: 'generate-signature/payin',
  GENERATE_SIGNATURE_PAYOUT: 'generate-signature/payout',
  GET_QR_DETAIL: 'qr-detail/',
  GET_VA_DETAIL: 'va-detail/',

  POST_DISTRIBUTE_BALANCE: 'dashboard/distributeBalance',
  POST_SUBMIT_CRYPTO: 'dashboard/submitCryptoForm',
  POST_SUBMIT_PRODUCT: 'dashboard/addProductMappingPrice',
  POST_SIMULATOR_PAYIN: 'sim-transaction-payin',
  POST_SIMULATOR_PAYOUT: 'sim-transaction-payout',
  POST_ADD_PARTNER: 'dashboard/registerPartner',
  POST_EDIT_PARTNER: 'dashboard/editPartner',
  POST_MANUAL_DISBURSEMENT: 'manual-disbursement',

  GET_ALL_INFORMATION: 'dashboard/getAllInformation',
  GET_INFORMATION: 'dashboard/getInformation',
  POST_INFORMATION: 'dashboard/addInformation',
  PUT_INFORMATION: 'dashboard/editInformation',

  GET_FAILED_PAYIN: 'dashboard/getFailedTransactions',

  GET_OVERDRAFT_LIST: 'dashboard/getOverdraft',
  ADD_OVERDRAFT: 'dashboard/addOverdraft',

  GET_OVERDRAFT_HISTORY_LIST: 'dashboard/getOverdraftHistory',
}
<template>
  <TopShapeItem
    :loading="loading"
    menulink="/settlement"
    title="Ready To Settle"
    :value="settle"
    subvalue=""
    :imgsrc="require('@/assets/img/idr-icon.png')"
  ></TopShapeItem>
  <!-- <TopShapeItem v-if="splitPayout == 1" :loading="loading" menulink="/balance-wallet" title="Ending Balance Wallet" :value="wallet" subvalue="" :imgsrc="require('@/assets/img/idr-icon.png')"></TopShapeItem>
  <TopShapeItem v-if="splitPayout == 1" :loading="loading" menulink="/balance-disbursement" title="Ending Balance Disbursement"  :value="disbursment" subvalue="" :imgsrc="require('@/assets/img/idr-icon.png')"></TopShapeItem> -->
  <TopShapeItem
    :loading="loading"
    menulink="/balance-history"
    title="ending balance Payout"
    :value="payout_total"
    subvalue=""
    :imgsrc="require('@/assets/img/idr-icon.png')"
  ></TopShapeItem>
  <TopShapeItem
    :loading="loading"
    menulink="/payin-transaksi"
    title="Receivables"
    :value="receivables"
    subvalue=""
    :imgsrc="require('@/assets/img/idr-icon.png')"
  ></TopShapeItem>
  <div class="row my-4 align-center">
    <div class="col-5">
      <div class="row">
        <div class="col-3 d-flex align-items-center">
          <h6 class="mb-0">Date Start</h6>
        </div>
        <div class="col-9 d-flex align-items-center">
          <VueDatePicker
            v-model="dateStart"
            format="dd-MM-yyyy"
            @update:model-value="toggleSearch"
          />
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="row">
        <div class="col-3 d-flex align-items-center">
          <h6 class="mb-0">Date End</h6>
        </div>
        <div class="col-9 d-flex align-items-center">
          <VueDatePicker
            v-model="dateEnd"
            format="dd-MM-yyyy"
            @update:model-value="toggleSearch"
          />
        </div>
      </div>
    </div>
    <div class="col-2">
      <v-row justify="center">
        <v-btn
          prepend-icon=""
          color="#5865f2"
          class="text-light text-title-1"
          @click="search"
          :disabled="searchDisabled"
        >
          <template v-slot:prepend>
            <v-icon class="fa-solid fa-magnifying-glass"></v-icon>
          </template>
          Search
        </v-btn>
      </v-row>
    </div>
  </div>
  <TopShapeItem
    :loading="loading"
    title="Daily Payin Trx"
    :value="payintrx"
    subvalue="Success PayIn"
    classname="fa-solid fa-circle-check"
  ></TopShapeItem>
  <TopShapeItem
    :loading="loading"
    title="Daily Payin Amount"
    :value="payinamount"
    subvalue="Success PayIn"
    :imgsrc="require('@/assets/img/idr-icon.png')"
  ></TopShapeItem>
  <TopShapeItem
    :loading="loading"
    title="Daily Payout Trx"
    :value="payouttrx"
    subvalue="Success PayOut"
    classname="fa-solid fa-circle-check"
  ></TopShapeItem>
  <TopShapeItem
    :loading="loading"
    title="Daily Payout Amount"
    :value="payoutamount"
    subvalue="Success PayOut"
    :imgsrc="require('@/assets/img/idr-icon.png')"
  ></TopShapeItem>
</template>

<script>
import TopShapeItem from "@/components/TopShapeItem";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";

export default {
  name: "TopItem",
  props: {
    item: undefined,
    splitPayoutProp: Number,
  },
  components: {
    TopShapeItem,
    VueDatePicker,
  },
  methods: {
    toggleSearch() {
      console.log("toggle");
      this.searchDisabled = true;
      if (this.dateStart != null && this.dateEnd != null) {
        this.searchDisabled = false;
      }
    },
    search() {
      this.$emit(
        "filterDate",
        moment(this.dateStart).format("yyyy-MM-DD"),
        moment(this.dateEnd).format("yyyy-MM-DD")
      );
    },
  },
  emits: ["filterDate"],
  data: () => ({
    loading: true,
    settle: 0,
    wallet: 0,
    disbursment: 0,
    payintrx: 0,
    payinamount: 0,
    receivables: 0,
    payouttrx: 0,
    payoutamount: 0,
    payout_total: 0,
    searchDisabled: false,
    splitPayout: 1,
    dateStart: moment([moment().year(), moment().month()]),
    dateEnd: moment(moment([moment().year(), moment().month()])).endOf("month"),
  }),
  mounted() {},
  watch: {
    item: {
      immediate: false,
      deep: true,
      handler() {
        this.settle = this.item.total.ready_settle;
        this.wallet = this.item.total.wallet_total;
        this.disbursment = this.item.total.disbursment_total;
        this.payintrx = this.item.total.trx;
        this.payinamount = this.item.total.amount;
        this.payouttrx = this.item.total.payouttrx;
        this.payoutamount = this.item.total.payoutamount;
        this.payout_total = this.item.total.payout_total;
        this.receivables = this.item.total.receivables;
        this.loading = false;
      },
    },
    splitPayoutProp: {
      immediate: true,
      handler() {
        this.splitPayout = this.splitPayoutProp;
      },
    },
  },
};
</script>

<style scoped></style>
